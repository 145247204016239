@import "../../../../styles/_chunk";

.ReviewSection__reviews {
  display: none;

  @include media-breakpoint-up(lg) {
    display: flex;
    align-items: center;
  }

  .ReviewSection__stars {
    display: flex;
    margin-right: 1rem;
    color: $text-muted;

    .star-rating-container {
      margin-right: 0.25rem;

      .star {
        svg {
          width: 16px;

          &.star-half-icon {
            width: 8px;
          }
        }
      }
    }
  }

  .ReviewSection__critics {
    display: flex;
    align-items: center;

    .ReviewSection__criticsScore {
      background-color: $metacritic-green;
      color: $white;
      font-weight: 700;
      width: 26px;
      height: 26px;
      font-size: 14px;
      margin-right: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
    }

    .ReviewSection__criticsLogo {
      display: flex;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }

      &--metacritic {
        width: 85px;
        height: 20px;
      }

      &--opencritic {
        width: 87px;
        height: 18px;
      }
    }
  }
}
